const GoLive = () => {
  return (
    <>
      <div className="container">
        <div className="d-flex flex-column align-items-center w-100">
          <h1>Go Live : 1, 2, 3</h1>
          <p className="text-center">deploy in two weeks, you relax , edwai does the work!</p>
          <div className="row">
            <div className="col-lg-5 d-flex justify-content-center">
              <img
                className="img-fluid"
                src="assets/img/live/live.png"
                alt="live-image"
              />
            </div>
            <div className="col-1"></div>
            <div className="col-lg-6">
              <div className="row flex-column h-100">
                <div className="col">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center">
                      <img src="assets/img/live/icon-1.png" alt="icon-1" />
                    </div>
                    <div className="col-9 mt-3">
                      <h3 className="fs-4 mb-0">Consultation & Assessment</h3>
                      <p style={{lineHeight: '25px'}}>
                        Begin your journey with a personalized consultation. Our
                        expert team will assess your needs, preferences, and
                        considerations to understand your unique requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center">
                      <img src="assets/img/live/icon-2.png" alt="icon-2" />
                    </div>
                    <div className="col-9">
                      <h3 className="fs-4 mb-0">Tailored AI Training</h3>
                      <p style={{lineHeight: '25px'}}>
                        Armed with insights from the consultation, we initiate a
                        targeted AI customization for assets that is in your
                        portfolio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center">
                      <img src="assets/img/live/icon-3.png" alt="icon-3" />
                    </div>
                    <div className="col-9">
                      <h3 className="fs-4 mb-0">Sales Guru Training</h3>
                      <p style={{lineHeight: '25px'}}>
                        Teach AI unique selling proposition including not
                        expected info clients might be asking.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-2 d-flex align-items-center">
                      <img src="assets/img/live/icon-4.png" alt="icon-4" />
                    </div>
                    <div className="col-9">
                      <h3 className="fs-4 mb-0">Onboarding</h3>
                      <p style={{lineHeight: '25px'}}>
                        Onboarding is seamless and takes a week. You will be
                        able to respond to customer or your dealer network
                        queries in every language in 2 weeks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoLive;
