import React, { useState } from "react";

const Contact = () => {
  const [ready, setReady] = useState(true);
  const [success, setSucccess] = useState(false);
  const [error, setError] = useState(false);
  function sendMail(e) {
    setReady(false);
    e.preventDefault();
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var phoneNumber = document.getElementById("phoneNumber").value;
    var subject = document.getElementById("subject").value;
    var message = document.getElementById("message").value;
    fetch(
      "https://api.goodforyou.cmosteknoloji.com/api/account/EdwaiRealEstateSendEmail",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          topic: subject,
          message,
        }),
      }
    ).then((response) => {
      if (response.status === 200) {
        setReady(true);
        setSucccess(true);
        setError(false);
        setTimeout(() => {
          setError(false);
          setSucccess(false);
        }, 5000);
      } else {
        setReady(true);
        setError(true);
        setSucccess(false);
        setTimeout(() => {
          setError(false);
          setSucccess(false);
        }, 5000);
      }
    });
  }
  return (
    <>
      <div className="space-bottom" id="contact">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 mt-5">
              <img
                className="img-fluid"
                src="assets/img/contact/contact.png"
                alt="Contant-Hero"
              />
            </div>
            <div className="col-xl-6 col-lg-8">
              <div className="title-area mb-0">
                <span className="sub-title mt-2">Contact Us</span>
                <h2 className="sec-title style2">Get In Touch</h2>
              </div>
              <div className="contact-form">
                <form onSubmit={(e) => sendMail(e)}>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        id="name"
                        required
                        placeholder="Your Name"
                        className="form-control style-border"
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="email"
                        id="email"
                        required
                        pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                        placeholder="Your Email"
                        className="form-control style-border"
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="tel"
                        id="phoneNumber"
                        required
                        pattern="\+[0-9]{1,3}[0-9]{3}[0-9]{3}[0-9]{4}"
                        placeholder="Phone Number"
                        className="form-control style-border"
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        id="subject"
                        required
                        placeholder="Subject"
                        className="form-control style-border"
                      />
                    </div>
                    <div className="col-12 form-group">
                      <textarea
                        required
                        placeholder="Message here.."
                        className="form-control style-border"
                        id="message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12 form-group mb-0">
                      <div
                        className="justify-content-center w-100"
                        style={{ display: ready ? "none" : "flex" }}
                      >
                        <span className="loader-primary" />
                      </div>
                      <button
                        style={{ display: !ready ? "none" : "inline" }}
                        className="global-btn w-100"
                      >
                        Send Now
                        <img src="assets/img/icon/right-icon.svg" alt="Edwai" />
                      </button>
                    </div>
                    <div>
                      <div
                        style={{ display: !success ? "none" : "flex" }}
                        className="col-12 rounded-3 align-items-center py-2 px-3 mt-2 bg-success"
                      >
                        <p className="text-white fs-5 mb-0">
                          The email has been sent successfully!
                        </p>
                      </div>
                      <div
                        style={{ display: !error ? "none" : "flex" }}
                        className="col-12 rounded-3 align-items-center py-2 px-3 mt-2 bg-danger"
                      >
                        <p className="text-white fs-5 mb-0">
                          An error occurred while sending the email!
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
