import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="bg-white">
      <footer
        className="footer-wrapper footer-layout5"
      >
        <div className="container">
          <div className="widget-area">
            <div className="row">
              <div className="col-md-6 col-xl-4">
                <div className="widget footer-widget widget-about">
                  <div className="about-logo">
                    <Link to="/">
                      <img
                        className="logo-img"
                        src="assets/img/logo.svg"
                        alt="Edwai"
                      />
                    </Link>
                  </div>
                  <div className="social-btn style4 mt-4">
                    <Link target="_blank" to="https://www.linkedin.com/company/edwai/" tabIndex={-1}>
                      <i className="fab fa-linkedin-in" />
                    </Link>
                    <Link target="_blank" to="https://www.instagram.com/heyedwai.tr/" tabIndex={-1}>
                      <i className="fab fa-instagram" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="widget footer-widget">
                  <h3 className="widget_title">Contact Us</h3>
                  <div className="widget-contact2">
                    <div className="widget-contact-grid">
                      <i className="fas fa-phone-alt" />
                      <div className="contact-grid-details">
                        <h6>
                          <Link to="tel:+17862205611">+17862205611</Link>
                          <p />
                        </h6>
                      </div>
                    </div>
                    <div className="widget-contact-grid">
                      <i className="fas fa-envelope" />
                      <div className="contact-grid-details">
                        <h6>
                          <Link to="mailto:hello@edwairealtor.com">hello@edwairealtor.com</Link>
                          <p />
                        </h6>
                      </div>
                    </div>
                    <div className="widget-contact-grid">
                      <i className="fas fa-map-marker-alt" />
                      <div className="contact-grid-details">
                        <h6>
                          3625 N Country Club Dr. Apt 1901 Aventura. FL 33180
                          <p />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright-wrap">
            <div className="row gy-3 justify-content-lg-between justify-content-center">
              <div className="col-auto align-self-center">
                <p className="copyright-text text-center">
                edwai Realtor @ 2024. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
