import React from 'react'

const Hero = () => {
    return (
        <section className='bg-white'>
            <div
                className="hero-wrapper hero-5"
                id="hero"
                style={{ backgroundImage: "url(assets/img/hero/hero_bg_5_1.png)" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="hero-style5 text-center">
                                <h1 className="hero-title text-black">
                                    Revolutionizing the Unique
                                    <span className="text-theme2"> Business</span> Landscape
                                </h1>
                                <p className="hero-text text-black">
                                    Next-generation AI for Enhanced Client Interaction and Sales Efficiency
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="hero-thumb5-1 d-flex justify-content-center">
                                <img style={{objectFit: 'cover'}} src="assets/img/hero/hero_thumb_5_1.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Hero