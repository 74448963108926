import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
const Features = () => {
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className="bg-dark2">
      <section className="blog-area-4 position-relative">
        <div className="shadow-shape-left" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="title-area text-center">
                <span className="sub-title style2">Sell Faster</span>
                <h2 className="sec-title style2 text-black">Features</h2>
                <p className="mt-3">
                  edwai: superhuman real estate sales accelerator
                </p>
              </div>
            </div>
          </div>
          <div className="row global-carousel blog-slider5 slider-shadow">
            <div className="row w-100 justify-content-center m-0">
              <div className="col-lg-4 px-2 col-sm-1 mb-2">
                <div className="blog-card h-100 style4">
                  <div className="blog-img p-4">
                    <img
                      className="rounded-3"
                      src="assets/img/blog/features-3.png"
                      alt="Edwai"
                    />
                  </div>
                  <div className="blog-content">
                    <h2 className="fs-3">Sell Properties</h2>
                    <p>
                      Sell properties by leveraging advanced marketing
                      strategies and cutting-edge technology to showcase
                      effectively to potential buyers. Personalized service,
                      Guiding clients through every step of the buying process
                      to ensure a seamless and satisfying experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 px-2 col-sm-1 mb-2">
                <div className="blog-card h-100 style4">
                  <div className="blog-img p-4">
                    <img
                      className="rounded-3"
                      src="assets/img/blog/features-2.png"
                      alt="Edwai"
                    />
                  </div>
                  <div className="blog-content">
                    <h2 className="fs-3">Dialogue in 10+ languages</h2>
                    <p>
                      Superior quality in communication, providing detailed,
                      personalized, and accurate responses that meet or exceed
                      client expectations. say in local language
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 px-2 col-sm-1 mb-2">
                <div className="blog-card h-100 style4">
                  <div className="blog-img p-4">
                    <img
                      className="rounded-3"
                      src="assets/img/blog/features-1.png"
                      alt="Edwai"
                    />
                  </div>
                  <div className="blog-content">
                    <h2 className="fs-3">Respond to questions</h2>
                    <p>
                      Round-the-clock support across multiple channels,
                      including WhatsApp, web chat, and call centers, ensuring
                      that no client inquiry goes unanswered, regardless of the
                      time zone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Features;
