import React from "react";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <section className="bg-white">
      <div className="about-area-5">
        <div className="shadow-shape-left" />
        <div className="container">
          <div className="row gy-40 gx-60 align-items-center justify-content-between">
            <div className="col-xl-5">
              <div className="about-thumb5">
                <div className="img1">
                  <img src="assets/img/normal/about_5-1.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="title-area mb-40">
                <h2 className="sec-title style2 text-black">
                  Maximizing Sales Through the Strategic Utilization of AI in
                  Real Estate
                </h2>
                <p className="sec-text text-black mb-30">
                  In today's dynamic real estate market, leveraging cutting-edge
                  AI technologies is no longer just an option but a necessity
                  for maximizing sales. By strategically integrating AI into
                  every facet of the real estate sales process, from initial
                  lead generation to closing deals, agents and agencies can
                  significantly enhance efficiency and customer satisfaction.
                </p>
                <p className="sec-text text-black">
                  In essence, by embracing AI-driven solutions, real estate
                  professionals can transcend traditional boundaries, unlock new
                  avenues for growth, and ultimately, elevate the entire sales
                  experience for both buyers and sellers alike.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
