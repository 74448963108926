import React, { useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import HelmetReact from "../elements/HelmetReact";
import Header from "../components/Header";
import Hero from "../components/Hero";
import About from "../components/About";
import Testimonial from "../components/Testimonial";
import CTA from "../components/CTA";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Counter from "../components/Counter";
import Contact from "../components/Contact";
import GoLive from "../components/GoLive";

const Home = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 500);
  }, []);
  return (
    <>
      {active === true && <Preloader />}
      <HelmetReact title={"Home"} />
      <Header />
      <Hero />
      <div className="space">
        <About />
      </div>
      <div className="space-bottom">
        <Counter />
      </div>
      <div className="space-bottom">
        <Features />
      </div>
      <div className="space-bottom">
        <GoLive />
      </div>
      <div className="space-bottom">
        <Testimonial />
      </div>
      <div className="space-bottom">
        <Contact />
      </div>
      <div className="space-bottom">
        <CTA />
      </div>

      <Footer />
    </>
  );
};

export default Home;
