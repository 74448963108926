import React, { useState } from "react";
import axios from "axios";

const CTA = () => {
  const [ready, setReady] = useState(true);
  const [successSubscribe, setSucccessSubscribe] = useState(false);
  const [errorSubscribe, setErrorSubscribe] = useState(false);
  function subscribe(e) {
    setReady(false);
    e.preventDefault();
    var email = document.getElementById("subscribeEmail").value;
    console.log(email);
    fetch(
      `http://api.goodforyou.cmosteknoloji.com/api/account/NewsLetter?email=${email}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {},
      }
    ).then((response) => {
      if (response.status === 200) {
        setReady(true);
        setSucccessSubscribe(true);
        setErrorSubscribe(false);
        setTimeout(() => {
          setErrorSubscribe(false);
          setSucccessSubscribe(false);
        }, 5000);
      } else {
        setReady(true);
        setErrorSubscribe(true);
        setSucccessSubscribe(false);
        setTimeout(() => {
          setErrorSubscribe(false);
          setSucccessSubscribe(false);
        }, 5000);
      }
    });
  }
  return (
    <section className="bg-white pt-1">
      <div className="container">
        <div className="cta-area-3 bg-cta">
          <div className="row justify-content-md-between align-items-center">
            <div className="col-lg-5">
              <div className="title-area mb-lg-0 text-lg-start text-center">
                <h2 className="sec-title text-black">
                  Subscribe To Our Newsletter
                </h2>
                <p className="sec-text text-black">
                  Subscribe to our newsletter to receive inspiration, ideas, and
                  news in your inbox.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="justify-content-center w-100 mb-5"
                style={{ display: ready ? "none" : "flex" }}
              >
                <span className="loader-primary" />
              </div>
              <form
                onSubmit={(e) => subscribe(e)}
                className="newsletter-form style2"
              >
                <div className="form-group">
                  <input
                    type="email"
                    id="subscribeEmail"
                    required
                    pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                    className="form-control"
                    placeholder="Your Email Address"
                  />
                  <button
                    style={{ display: !ready ? "none" : "inline" }}
                    className="global-btn text-white"
                  >
                    Explore Now
                  </button>
                </div>
              </form>
              <div>
                <div
                  style={{ display: !successSubscribe ? "none" : "flex" }}
                  className="col-12 rounded-3 align-items-center py-2 px-3 mt-2 bg-success"
                >
                  <p className="text-white fs-5 mb-0">
                    Subscription process has been completed successfully!
                  </p>
                </div>
                <div
                  style={{ display: !errorSubscribe ? "none" : "flex" }}
                  className="col-12 rounded-3 align-items-center py-2 px-3 mt-2 bg-danger"
                >
                  <p className="text-white fs-5 mb-0">
                    An error occurred while subscribing!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
