import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const Counter = () => {
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-sm-6 col-lg-3 ">
          <div className="counter-card py-4 ps-3 pe-5 w-100 border border-2 border-primary rounded-3 style2">
            <div className="media-body">
              <h2 className="counter-card_number">
                <TrackVisibility>
                  {({ isVisible }) =>
                    isVisible ? (
                      <span className="counter-number text-primary">
                        <CountUp delay={0} start={0} end={10} />+
                      </span>
                    ) : (
                      <span className="counter-number text-primary">0 +</span>
                    )
                  }
                </TrackVisibility>
              </h2>
              <p className="counter-card_text">Property Developers</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 ">
          <div className="counter-card py-4 ps-3 pe-5 w-100 border border-2 border-primary rounded-3 style2">
            <div className="media-body">
              <h2 className="counter-card_number">
                <TrackVisibility>
                  {({ isVisible }) =>
                    isVisible ? (
                      <span className="counter-number text-primary">
                        <CountUp delay={0} start={0} end={3} />
                      </span>
                    ) : (
                      <span className="counter-number text-primary">0</span>
                    )
                  }
                </TrackVisibility>
              </h2>
              <p className="counter-card_text">Different Channels</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 ">
          <div className="counter-card py-4 ps-3 pe-5 w-100 border border-2 border-primary rounded-3 style2">
            <div className="media-body">
              <h2 className="counter-card_number">
                <TrackVisibility>
                  {({ isVisible }) =>
                    isVisible ? (
                      <span className="counter-number text-primary">
                        <CountUp delay={0} start={0} end={90} />+
                      </span>
                    ) : (
                      <span className="counter-number text-primary">0 +</span>
                    )
                  }
                </TrackVisibility>
              </h2>
              <p className="counter-card_text">Client Discussion per hour</p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 ">
          <div className="counter-card py-4 ps-3 pe-5 w-100 border border-2 border-primary rounded-3 style2">
            <div className="media-body">
              <h2 className="counter-card_number">
                <TrackVisibility>
                  {({ isVisible }) =>
                    isVisible ? (
                      <span className="counter-number text-primary">
                        <CountUp delay={0} start={0} end={10} />+
                      </span>
                    ) : (
                      <span className="counter-number text-primary">0 +</span>
                    )
                  }
                </TrackVisibility>
              </h2>
              <p className="counter-card_text">Different Languages</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
