import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 250) {
        setScroll(false);
      } else if (window.pageYOffset > 250) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
  }, []);

  const mobileMenu = () => {
    setActive(!active);
  };

  const searchControl = (active) => {
    setSearch(active);
  };

  const sidebarControl = (active) => {
    setSidebar(active);
  };

  return (
    <>
      {/* Header Area */}

      <header className="nav-header header-layout5">
        <div className="header-top d-none d-lg-block">
          <div className="row justify-content-around align-items-center gy-2">
            <div className="col-auto">
              <div className="header-links">
                <ul>
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    3625 N Country Club Dr. Apt 1901 Aventura. FL 33180
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-auto">
              <div className="header-links ps-0">
                <ul>
                  <li>
                    <i className="fas fa-phone-alt" />
                    <Link to="tel:+17862205611">+17862205611</Link>
                  </li>
                  <li>
                    <i className="fas fa-envelope" />
                    <Link to="mailto:hello@edwairealtor.com">
                      hello@edwairealtor.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="header-top-bg-shape"></div>
        </div>
        <div className={`sticky-wrapper ${scroll && "sticky"}`}>
          {/* Main Menu Area */}
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="header-logo">
                  <Link to="/">
                    <img
                      className="logo-img"
                      src="assets/img/logo.svg"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-auto d-block">
                <div className="header-button">
                  <a href="#contact" className="bg-transparent-btn">
                    Contact Us
                    <img className="ms-2" src="assets/img/icon/right-icon2.svg" alt="Bizmaster" />
                  </a>
                </div>
              </div>
              {/* <div className="col-auto ms-xxl-4 d-xl-block d-none">
                <div className="header-wrapper">
                  <div className="header-button">
                    <Link to="/about" className="global-btn style-border3">
                      Get a quote <i className="fas fa-arrow-right ms-2" />
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
