import React from "react";
import Slider from "react-slick";
const Testimonial = () => {
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {},
      },
      {
        breakpoint: 992,
        settings: {},
      },
      {
        breakpoint: 768,
        settings: {},
      },
    ],
  };
  return (
    <section className="bg-white">
      <div className="testimonial-area-5 overflow-hidden">
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6">
              <div className="testi-area-slider4">
                <div className="row global-carousel testi-slider-4">
                  <div className="px-3">
                    <div className="testi-card style3">
                      <div className="testi-card_content">
                        <h2>You build more, while we help you sell more</h2>
                        <p
                          className="testi-card_text"
                          style={{ fontSize: "1.25rem" }}
                        >
                          We know you want to sell more and build more that is
                          why we are here.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column align-items-center">
              <img
                className="img-fluid"
                src="assets/img/testimonial/testimonal.png"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
